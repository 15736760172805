/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Links,
  Meta,
  Scripts,
  isRouteErrorResponse,
  useRouteError,
} from "@remix-run/react";

import { Logo } from "~/components/ui/logo";

const is404 = (error: unknown) => {
  if (!isRouteErrorResponse(error)) return false;
  return error.status === 404;
};

export const RootErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  const homeUrl = `/`;
  return (
    <html lang="en">
      <head>
        <title>Error</title>
        <Meta />
        <Links />
      </head>
      <body className=" work-sans  h-screen min-h-full bg-black text-base tracking-normal text-gray-600">
        {/* Error page UI */}
        {is404(error) ? (
          <main className="relative isolate min-h-full bg-black">
            <img
              src="/not-found.avif"
              alt="Error page"
              className="absolute inset-0 -z-10 h-full w-full object-cover object-bottom"
            />
            {/* Gradient Background */}
            <div className="absolute inset-0 -z-10 bg-gradient-to-b from-black to-transparent mix-blend-multiply"></div>
            <div className="relative z-10 mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
              <p className="text-base font-semibold text-white">404</p>
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
                Page not found
              </h1>
              <p className="mt-4 text-xl text-white/70 sm:mt-6">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-10 flex justify-center">
                <a
                  href={homeUrl}
                  className="text-base font-semibold text-white"
                >
                  <span aria-hidden="true">&larr;</span> Back to home
                </a>
              </div>
            </div>
          </main>
        ) : (
          <section className="flex h-full w-full items-center dark:bg-gray-900 dark:text-gray-100 sm:p-16 ">
            <div className="container mx-auto my-8 flex flex-col items-center justify-center space-y-8 px-5 py-10 text-center sm:max-w-md md:max-w-7xl">
              <Logo className="h-40 bg-black" />
              <p className="text-3xl">
                Looks like our services are currently offline
              </p>
              <a
                rel="noopener noreferrer"
                href={homeUrl}
                className="rounded px-8 py-3 font-semibold dark:bg-violet-400 dark:text-gray-900"
              >
                Back to homepage
              </a>
            </div>
          </section>
        )}
        <Scripts />
      </body>
    </html>
  );
};
